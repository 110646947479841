import React, { useEffect, useState } from 'react';
// import * as React from 'react';
import Header from '../header/header'
import { useNavigate, useParams } from "react-router-dom";
import './details.css';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const AutoplaySlider = withAutoplay(AwesomeSlider);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className='tabcontent'>
        {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function Details() {


  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate()
  const { id } = useParams()
  const [names, setName] = useState('')
  const [cnames, setCName] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    let name = ''
    let cname = ''
    if (id == 'Electrical-Actuators') {
      name = 'Electrical Actuators'
      cname = ''
    } else if (id == 'Mechanical-Seals') {
      name = 'Mechanical Seals'
      cname = 'Mechanical Seals'
    } else if (id == 'Pumps-Boiler-Feed-Pumps') {
      name = 'Pumps Boiler Feed Pumps'
      cname = 'Pumps Boiler Feed Pumps'
    } else if (id == 'Condensate-Extraction-Pump') {
      name = 'Condensate Extraction Pump'
      cname = 'Condensate Extraction Pump'
    } else if (id == 'Gravimaetric-Coil-Feeder') {
      name = 'Gravimaetric Coil Feeder'
      cname = 'Gravimaetric Coil Feeder'
    } else if (id == 6) {
      name = ''
      cname = ''
    } else if (id == 7) {
      name = ''
      cname = ''
    } else if (id == 8) {
      name = ''
      cname = ''
    } else if (id == 9) {
      name = ''
      cname = ''
    } else {
      name = ''
      cname = ''
    }

    setCName(cname)
    setName(name)
  }, [id])
  return (
    <>
      <Header />
      <section className="company-page" >
        <div className='container'>

          {id == 'Electrical-Actuators' &&
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example">
                  <Tab label="SHANG YI BRAND Actuators" {...a11yProps(0)} />
                  <Tab label="Changzhou Schneider Actuators" {...a11yProps(1)} />
                  <Tab label="TET BRAND ACTUATORS" {...a11yProps(2)} />
                  <Tab label="RAGA Make Actuators" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <section id="SHANGYI">
                  <div className='space-between flex-wrap'>
                    <h4>{cnames}</h4>
                    <img src='https://www.etmex.net/images/logo06.jpg' />
                  </div>
                  <br />
                  <div class='detail-container'>
                    <div class='img'>
                      <img src='https://www.etmex.net/images/AI-MI.jpg' />
                    </div>
                    <div class='text'>
                      <p className='bname'> SHANG YI BRAND Actuators</p>
                      <p>AI/MI range of intelligent actuator basic to the technology introduced from ROTORK in England adopting
                        today’s most advanced large-scale integrated circuit is a new generation actuator with intelligence digit
                        and multi-functions.
                      </p><br />
                      <p>Implement standard: Q/YXBM 470-2206 (AI/MI intelligent electric actuator and explosion proof
                        intelligent electric actuator.)
                      </p> <br />
                      <p>The AI/MI range of intelligent actuator is driven by 3 phase asynchronism electromotor which finally
                        rotate the output shaft through the worm and worm gear. With the shift of mechanism the actuator can
                        be operated handly or electrically: When the handle of the shift mechanism is engaged in the manual
                        position, rotating the handwheel will drive the output shaft through the clutch; when actuator is
                        operated by electricity, the shift mechanism will return to the electric position automatically and clutch
                        mesh with worm gear then output shaft is driven by motor.
                      </p> <br />
                      <p>The rotation of the output shaft is transmitted to Hall Effect pulse sensor through speed mechanism and
                        the axial moving aroused by worm axial force is transmitted to switch mechanism, both of which are
                        used to control travel and torque.
                      </p> <br />
                      <p>The director of the intelligent electric actuators accepts the analog-value, switch-value signal or fieldbus
                        signal and makes the position of the output shaft consistent with the input signal, accomplishes the
                        location. You can also locate position that control system has set up according to interlock control, 2
                        wire remote control or ESD signal.
                      </p> <br />
                      <p className='sub-bname'>Main Character:</p>
                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'>Classic device of mechanical torque protection working stable and reliable.</li>
                        <li className='inner-listone'>Hall Effect pulse sensor with high precision</li>
                        <li className='inner-listone'>Powerful large-scale integrated chip</li>
                        <li className='inner-listone'>Remote control make operation easier (Supply both English and Chinese menu)</li>
                        <li className='inner-listone'>Non intrusive setting-double sealing without through shaft design.</li>
                      </ul>
                      <br />
                      <p className='sub-bname'>Function:</p>
                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'> Remote Control</li>
                        <li className='inner-listone'> Valve Position Indication</li>
                        <li className='inner-listone'> Operation Indication</li>
                        <li className='inner-listone'> Travel Limited Protection</li>
                        <li className='inner-listone'> Torque Limited Protection</li>
                        <li className='inner-listone'> Indication Contact State</li>
                        <li className='inner-listone'> Contact type selection (normal open or close)</li>
                        <li className='inner-listone'> Instantaneous commutating protection</li>
                        <li className='inner-listone'> Local Control spot moving/maintain moving</li>
                        <li className='inner-listone'> Overtorque protection bypass</li>
                        <li className='inner-listone'> 2 wire remote control</li>
                        <li className='inner-listone'> Conditional control</li>
                        <li className='inner-listone'> External Interlock</li>
                        <li className='inner-listone'> LESD override protection</li>
                        <li className='inner-listone'> Interuptertimer setting</li>
                      </ul>
                    </div>
                  </div>
                </section>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <section id="Changzhou">
                  <div className='space-between flex-wrap'>
                    <h4>{cnames}</h4>
                    <img src='https://www.etmex.net/images/logo01.jpg' />
                  </div>
                  <br />

                  <div class='detail-container'>
                    <div class='img'>
                      <img src='https://www.etmex.net/images//CZD1.jpg' />
                    </div>
                    <div class='text'>
                      <p className='bname'>Changzhou Schneider Actuators</p>
                      <p>SND Series is the ultimate open-loop control system control elements (such as valves) to control the operation of a device, which SND-Z series is suitable for valves, valve, throttle, such as water control, SND-Q and SND-DQ series is suitable for ball valves, butterfly valves, throttle and other controls.</p><br /><p>SMC actuators can be controlled individually or collectively. Not only local operating, but also remote control are provided. In addition to Basic SMC, there are other models of SMC such as Explosion-proof ( Explosion- Proof Grade d11BT4) ; Integral Explosion-Proof ; Radiation- Proof ; Auto-Regulation; Two-Wired; Two-Speed etc. Various actuators of different protection, such as Water – Resistant, Cold = Resistant, Marine Type, Fire- Proof, are available on the request of customers. With so many kinds of SMC actuators, we are able to meet various requirements.
                      </p> <br />
                      <p className='sub-bname'>Features:</p>
                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'>Non-intrusive design, debugging is simple, intuitive</li>
                        <li className='inner-listone'>Travel, torque measurement accuracy, reliability</li>
                        <li className='inner-listone'>Interface of choice in the English language</li>
                        <li className='inner-listone'>Control of the state of the password lock function</li>
                        <li className='inner-listone'>Has a two-speed (variable speed slow off) function</li>
                        <li className='inner-listone'>Comprehensive data logging</li>
                        <li className='inner-listone'> Adaptive self-diagnosis function</li>
                        <li className='inner-listone'>Automatic phase sequence correction</li>
                        <li className='inner-listone'>Run in fault diagnosis</li>
                        <li className='inner-listone'>Network transport functions</li>
                        <li className='inner-listone'>Has a two-speed (variable speed slow off) function</li>
                        <li className='inner-listone'>Motor overheating protection</li>
                        <li className='inner-listone'>Non-intrusive design, debugging is simple, intuitive</li>
                        <li className='inner-listone'>Instantaneous reversal protection</li>
                        <li className='inner-listone'>Interface of choice in the English language</li>
                        <li className='inner-listone'>High-speed off valve required to achieve</li>
                        <li className='inner-listone'>Protection class IP68 (water4 m68 hours)</li>
                        <li className='inner-listone'>Explosion-proof products with the ordinary product and the two series</li>

                      </ul>
                    </div>
                  </div>
                  <br />

                  <div class='detail-container'>
                    <div class='img'>
                      <img src='https://www.etmex.net/images/CZD2.jpg' />
                    </div>
                    <div class='text'>

                      <p className='sub-bname'>WORKING CONDITIONS:</p>
                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'>Power supply: 380 ± 10 % V 50 ± 10 % Hz</li>
                        <li className='inner-listone'>Optional power supply: 380 ~ 660V 50 , 60Hz (note when ordering).</li>
                        <li className='inner-listone'>Ambient temperature: -20 ~60 ℃ Optional temperature: -40 ~70 ℃</li>
                        <li className='inner-listone'>Relative humidity: ≤ 90 % (25 ℃)</li>
                        <li className='inner-listone'>Protection class: IP68 (water4 mDark 68 hours).</li>
                        <li className='inner-listone'>Ex-mark: ExdΙ , Exdib Ⅱ BT4 and Exdib Ⅱ CT4.</li>
                        <li className='inner-listone'>The motor is short-term work system, rated operating time of 15 minutes. F class insulation</li>
                      </ul>
                      <br />
                      <p className='sub-bname'>PRODUCT RANGE/ MODELS: </p>
                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'>SND Series</li>
                        <li className='inner-listone'>SND – STZ Series</li>
                        <li className='inner-listone'>SND – QDZ Series</li>
                        <li className='inner-listone'>HZD Series (Nuclear Devices)</li>
                        <li className='inner-listone'>HQJ Series (Nuclear Devices)</li>
                        <li className='inner-listone'>Non-intrusive design, debugging is simple, intuitive</li>
                        <li className='inner-listone'>HDQ Series (Nuclear Devices)</li>
                        <li className='inner-listone'>HQB Series (Nuclear Devices)</li>
                        <li className='inner-listone'>ZC Series (Flameproof)</li>

                      </ul>

                    </div>
                  </div>
                </section>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <section id="TETBRAND">
                  <div className='space-between flex-wrap'>
                    <h4>{cnames}</h4>
                    <img src='https://www.etmex.net/images/logo13.jpg' />
                  </div>
                  <br />
                  <div class='detail-container'>

                    <div class='text'>
                      <p className='bname'>TET BRAND ACTUATORS</p>
                      <p>SMC series valve electric actuators, a multi-turn type, are designed with Limitorque technology from USA. SMC actuators are widely applied in such fields as petroleum, chemical engineering, water industry, metallurgy, marine, light, industry, food-stuff industry and so on.
                      </p><br /><p>SMC actuators can be controlled individually or collectively. Not only local operating, but also remote control are provided. In addition to Basic SMC, there are other models of SMC such as Explosion-proof ( Explosion- Proof Grade d11BT4) ; Integral Explosion-Proof ; Radiation- Proof ; Auto-Regulation; Two-Wired; Two-Speed etc. Various actuators of different protection, such as Water – Resistant, Cold = Resistant, Marine Type, Fire- Proof, are available on the request of customers. With so many kinds of SMC actuators, we are able to meet various requirements.
                      </p> <br />
                      <p className='sub-bname'>THE WORKING CONDITION OF BASIC MODEL SMC:</p>
                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'>Ambient Temperature: -20 ~ 50 deg. Cel.</li>
                        <li className='inner-listone'>Relative Humidity: no more htan 90% (25 deg. Cel.) </li>
                        <li className='inner-listone'>Sea Level Height: no more than 1000m </li>
                        <li className='inner-listone'>Working Circumstances: without flammable, explosive, corrosive medium </li>
                        <li className='inner-listone'>Protection Grade: IP65 ~ IP67 ( Specify when speaking for IP68 submarine type) </li>
                        <li className='inner-listone'>Power Supply: 380V, 50 Hz </li>

                      </ul>
                      <br />
                      <p className='sub-bname'>PRODUCT RANGE / MODELS:</p>

                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'>SMC Series</li>
                        <li className='inner-listone'>SMC – JA Series</li>
                        <li className='inner-listone'>SMC – HBC Series </li>
                        <li className='inner-listone'>SMC – GSQ Series </li>
                        <li className='inner-listone'>OOM Series </li>
                        <li className='inner-listone'>OOM – HBC Series</li>
                        <li className='inner-listone'>QT Series</li>
                        <li className='inner-listone'>KQ Series </li>
                        <li className='inner-listone'>Intelligent Type Actuator</li>
                        <li className='inner-listone'>Field Bus Contol Actuator </li>

                      </ul>
                    </div>
                  </div>
                </section>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <section id="RAGA">
                  <div className='space-between flex-wrap'>
                    <h4>{cnames}</h4>
                    <img src='https://www.etmex.net/images/logo05.jpg' />
                  </div>
                  <br />
                  <div class='detail-container'>
                    <div class='img'>
                      <img src='https://www.etmex.net/images/raga.jpg' />
                    </div>
                    <div class='text'>
                      <p className='bname'>RAGA Make Actuators</p>
                      <p>WENZHOU RICKEY CONTROL EQUIPMENT Co., Ltd. is a professional research, development, production and intelligent electric valve actuators for high-tech enterprises. Company is located in China’s Pearl of the Orient – The Shanghai and Ou Jiang River – Wenzhou.Company production base covers an area of 40,000 square meters, plant and comprehensive facilities construction area of 20,000 square meters. Strong technical force, with R & D centers. The company has obtained the results of 13 national invention patents, utility patents of new technologies and state-level new products.
                      </p><br /><p>The company has the world’s leading import processing equipment, the United States, Japan, Italy, Bulgaria, the production of horizontal machining centers, vertical machining centers in Italy, Japan and SMT production lines, the German Vertical & Horizontal machining centers and high sophisticated processing equipment. Company of “quality first, reputation first, service first” business philosophy, strict implementation of GB standards, the full implementation of ISO9001 quality management system, the production of intelligent electric valve actuators series. Over the years, the company’s sales and service network throughout the country, for the major and small power plants to provide ancillary services.
                      </p> <br />
                      <br /><p>Daqing Oilfield Company is Xinjiang, Liaohe, Dagang, Henan, Jiangsu and Natural Gas Group Corporation, China Petroleum and Chemical Group Corporation and a number of large state enterprises network units. Quality is the life, to provide customers with quality and efficient service is the eternal theme of constant technical innovation is the soul of development is our common pursuit.</p> <br />

                      <p className='sub-bname'>OVERVIEW</p>
                      <p>New generation of intelligent RQM Ⅲ Series actuators can be set by an independent non-invasive device for its quick setting, inspection and inquiry.Actuators using dot matrix LCD display, Chinese, digital, graphics torque, valve position, limit setting, such as working conditions and alarms, as shown in Figure 1-1, which greatly facilitates the user’s operation. The actuator has an automatic function and explosion protection functions. Even in hazardous areas also need to open the lid of electric actuators are working to adjust, fault diagnosis can be carried out. Fieldbus communication card can be configured to work full-distributed computer systems, remote communication, remote capture, remote diagnostics and maintenance. Because of its advanced, reliable and easy to operate features, welcomed by the majority of users, has been widely used in petroleum, electric power, metallurgy, water treatment and other industries.
                      </p>
                      <br />
                      <p className='sub-bname'>MAIN TECHNICAL INDICATORS:</p>
                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'>Input Signal: 4mA ~ 20mA; 1VDC ~ 5VDC; 24VDC pulse and level signals fieldbus signal</li>
                        <li className='inner-listone'>Power supply: 380VAC/50Hz 220VAC/50Hz, or custom</li>
                        <li className='inner-listone'>Basic error limit: ≤ 1%</li>
                        <li className='inner-listone'>Stroke repeatability error control mechanism : ≤ ± 1%</li>
                        <li className='inner-listone'>Dead zone: 0.1% to 9.9% adjustable </li>
                        <li className='inner-listone'>Protection class: IP68 </li>
                        <li className='inner-listone'>Explosion-proof: proof mark: Exd Ⅱ CT4 </li>
                        <li className='inner-listone'>Ambient temperature: -30 ℃ ~ +70 ℃ </li>
                        <li className='inner-listone'>Input and output channels are adopted for optical isolation</li>
                        <li className='inner-listone'>2.10 Humidity: ≤ 95% </li>

                      </ul>
                      <br />
                      <p className='sub-bname'>PRODUCT RANGE / MODELS:</p>

                      <ul style={{ marginLeft: '30px' }} className='inner-list'>
                        <li className='inner-listone'>SMC Series</li>
                        <li className='inner-listone'>SMC – JA Series</li>
                        <li className='inner-listone'>SMC – HBC Series </li>
                        <li className='inner-listone'>SMC – GSQ Series </li>
                        <li className='inner-listone'>OOM Series </li>
                        <li className='inner-listone'>OOM – HBC Series</li>
                        <li className='inner-listone'>QT Series</li>
                        <li className='inner-listone'>KQ Series </li>
                        <li className='inner-listone'>Intelligent Type Actuator</li>
                        <li className='inner-listone'>Field Bus Contol Actuator </li>

                      </ul>
                    </div>
                  </div>
                </section>
              </TabPanel>
            </>
          }

          {id == 'Mechanical-Seals' &&
            <div>
              {/* <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Electrical-Actuators')}>Electrical Actuators</a>&lt;-&gt; {names} &lt;-&gt;<a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Pumps-Boiler-Feed-Pumps,Condensate-Extraction-Pump,OCCW,CCW-Pump')}>Pumps-Boiler Feed Pumps, Condensate Extraction Pump, OCCW, CCW Pump </a>&lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Gravimaetric-Coil-Feeder,EPC-Companies,Others')}>Gravimaetric Coil Feeder, EPC Companies, Others </a></p> */}

              <div className='space-between flex-wrap'>
                <h4>{cnames}</h4>
                <img src='https://www.etmex.net/images/logo04.jpg' />
              </div>
              <br />
              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.etmex.net/images/lehe1.jpg' /><br />
                  <img src='https://www.etmex.net/images/lehe.jpg' />
                </div>
                <div class='text'>
                  <p className='bname'>Shanghai Lehe Mechanical Seals</p>
                  <p>SHANGHAI LEHE FLUID MACHINERY Co., Ltd. located in famous international metropolis Shanghai, and settled in Shanghai Baoshan Urban Industrial Park, covers an area of ​​7260 square meters and convenient transportation from Shanghai Hongqiao International Airport and 15 kilometers from the Shanghai People’s Square 20 kilometers. Relying on strong technical R & D and production capacity, specialized machinery, seal R & D, production, and provide satisfactory products.
                  </p><br /><p>The 1980s “Music Hop” to develop and produce a variety of mechanical seal products, mainly for the power plant, petrochemical, paper, aerospace and other fields, in which boiler feed water pump mechanical seal with bellows for the international initiative, the national patent (Patent No. ZL2 2006 2004248570.0), in the domestic market share of 70%, and are exported to India, Pakistan, Vietnam and other countries.
                  </p> <br />
                  <p className='sub-bname'>PRODUCTS:</p>
                  <ul style={{ marginLeft: '30px' }} className='inner-list'>
                    <li className='inner-listone'> LTJ-GJA spring cartridge BFP mechanical seal</li>
                    <li className='inner-listone'> LBJ-GJA metal bellows cartridge BFP mechanical seal</li>
                    <li className='inner-listone'> LTJ-GJB spring cartridge BFP mechanical seal</li>
                    <li className='inner-listone'> LBJ-GJB metal bellows cartridge BFP mechanical seal</li>
                    <li className='inner-listone'> LTJ-QZ pre-spring cartridge mechanical seal pumps</li>
                    <li className='inner-listone'> LBJ-QZ cartridge metal bellows mechanical seal pump front</li>
                    <li className='inner-listone'> LTJ-NJA spring cartridge mechanical seal grout</li>
                    <li className='inner-listone'> LTJ-NJB spring cartridge mechanical seal grout</li>
                    <li className='inner-listone'> LTJ / CS Spring cartridge mechanical seal</li>
                    <li className='inner-listone'> LBJ / CS cartridge metal bellows mechanical seal</li>
                    <li className='inner-listone'> LTJ / A spring cartridge mechanical seal</li>
                    <li className='inner-listone'> LBJ / B cartridge metal bellows mechanical seal</li>
                    <li className='inner-listone'> LBJ / C cartridge metal bellows mechanical seal</li>
                    <li className='inner-listone'> LB / IU rubber bellows mechanical seal</li>
                    <li className='inner-listone'> LT/SS1 single spring mechanical seal</li>
                    <li className='inner-listone'> LT/SS2 single spring mechanical seal</li>
                    <li className='inner-listone'> LT/BS1 wave line spring mechanical seal</li>
                    <li className='inner-listone'> LT/BS2 wave spring mechanical seal</li>
                    <li className='inner-listone'> LLT/MS1 multi-spring mechanical seal</li>
                    <li className='inner-listone'> LT/MS2 multi-spring mechanical seal</li>
                    <li className='inner-listone'> LB/MB1 metal bellows mechanical seal</li>
                    <li className='inner-listone'> LB/MB2 metal bellows mechanical seal</li>
                    <li className='inner-listone'> LB/MB3 metal bellows mechanical seal</li>
                    <li className='inner-listone'> LB-GS metal bellows mechanical seal</li>
                    <li className='inner-listone'> WJH heat exchanger</li>
                    <li className='inner-listone'> NJH heat exchanger</li>
                    <li className='inner-listone'> LCG magnetic filter</li>
                    <li className='inner-listone'> LXFQ cyclone</li>

                  </ul>
                  <br />

                </div>
              </div>

            </div>}

          {id == 'Pumps-Boiler-Feed-Pumps' &&
            <div>
              {/* <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Electrical-Actuators')}>Electrical Actuators</a>&lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Mechanical-Seals-&-High-Pressure-Valves')}>Mechanical Seals & High Pressure Valves </a>&lt;-&gt; {names} &lt;-&gt;<a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Gravimaetric-Coil-Feeder,EPC-Companies,Others')}>Gravimaetric Coil Feeder, EPC Companies, Others </a></p> */}
              <div className='space-between flex-wrap'>
                <h4>{cnames}</h4>
                <img src='https://www.etmex.net/images/logo11.jpg' />
              </div>
              <br />
              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.etmex.net/images/spem.jpg' />

                </div>
                <div class='text'>
                  <p className='bname'>SHANGHAI POWER EQUIPMENT MANUFACTURE CO., LTD.</p>
                  <p>SPEM adheres to the philosophy of “satisfied program, eminent manufacture——boosting clean energy” to carry out company’s “Two Million” strategy, that is, offering the auxiliaries for 1000 megawatt thermal power set and 1000 megawatt class nuclear power set, following the state’s general guidelines of “energy-saving and emission-reducing, developing clean energy”. SPEM is unswervingly seeking new breakthrough via constant innovation.
                  </p><br /> <p>Gearing to the needs of market and future, SPEM, on the way to the advanced manufacturing, always dedicates itself to design and make sophisticated energy-saving type BFP set as well as high performance hydraulic coupling, HT/HP valves and welding consumables, thus fulfilling its social responsibility for sustainable development of economy and society with quality product, satisfactory service and other act beneficial to human being.
                  </p> <p>With first-class technology, good faith, all-round service program, and global market eyeshot, SPEM will make its mission clear to all: providing clients with the best products by bending itself to innovation and progress. SPEM will take its courage in both hands in China’s development of power equipment industry.
                  </p> <br />
                  <p className='sub-bname'>BASIC PRINCIPALS :</p>
                  <p>With the development of technology applied in power plant, boiler feed water pump is being improved towards high-capacity and high-head requirement. SPEM possesses BFP core design technology, including more than a dozen of independent intellectual properties. Many series of BFP products have been localized via introducing advanced technology and self-innovation.</p>
                  <br /> <p>SPEM’s boiler feed pump series, as a centrifugal pump with multistage, incorporates an integrated cartridge which can be withdrawn as a whole and barrel design with double casings. The rigid rotor allows pump in short time duty under partial vaporization. The single balance piston and labyrinth seal basically avoids axial contact between rotor and stator shaft. BFP features compact size, less stages, high efficiency as well as reliable operation. They have been widely applied in 50MW~1000MW thermal nuclear and power plants. According to drive mode, BFP for large scale power plant can be divided into two types: motor and turbo pump.</p>
                  <br /> <p>As an enterprise which earliest developed BFP, SPEM has become a professional leading one and now is seizing on the biggest shares of domestic power product market, with the top design capability and sufficient experience.</p><br />

                </div>
              </div>
            </div>}

          {id == 'Gravimaetric-Coil-Feeder' &&
            <div>
              {/* <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Electrical-Actuators')}>Electrical Actuators</a>&lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Mechanical-Seals-&-High-Pressure-Valves')}>Mechanical Seals & High Pressure Valves </a>&lt;-&gt;<a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Pumps-Boiler-Feed-Pumps,Condensate-Extraction-Pump,OCCW,CCW-Pump')}>Pumps-Boiler Feed Pumps, Condensate Extraction Pump, OCCW, CCW Pump </a>&lt;-&gt; {names} </p> */}
              <div className='space-between flex-wrap'>
                <h4>{cnames}</h4>
                <img src='https://www.etmex.net/images/logo12.jpg' />
              </div>
              <br />
              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.etmex.net/images/speri1.jpg' />

                </div>
                <div class='text'>
                  <p className='bname'>SPERI Make Coal Feeders</p>
                  <p>SPERI mainly engages in research on the key and common technologies of large-scale fossil power and nuclear power equipments and their automatic systems. Combining the scientific experiment research and technical service, design and general contracting projects, and the supervision and management over the equipment and construction of projects, and provides high-tech automatic control products and auxiliary products as well as electromechanical integration products.

                    <br />
                  </p><br /> <p>SPERI organizes and takes charge of major equipment research and manufacture plans such as the technical introduction and assimilation of the whole-set 300MW and 600MW fossil power generating units and over 500 key scientific and technological projects of the State and Shanghai. It has undertaken thousands of scientific research development projects consigned by the enterprises of the power equipment and electric power industries, and gained 186 major scientific and technological fruit awards and new product awards of the States, ministries and Shanghai. It has 104 States patents to its name, including 58 invention patents.

                  </p><br /> <p>SPERI is an affiliated unit of the China Society of Power Engineering a, the secretary-general unit of China Standardization Committee on Steam Turbines as well as its director commissioner, the secretary-general unit of the Boiler Branch of China Standardization Committee on Boilers and Pressure Vessels as well as its director commissioner, the State Quality Supervision and Testing Center for Thermal Power Equipment. SPERI has the right to grant master’s degrees of thermal power engineering and power machinery and engineering majors. It sponsors the national first-class publications Journal of Power Engineering and Power Equipment. It is the council unit of China Machinery Industry Federation, the vice-chairman unit of China Electrical Equipment Industry Association and the director unit of the information network of the power equipment industry of China.


                  </p><br /> <p>SPERI keeps in close conjunction with enterprise’s development strategy and follows the state’s development strategy. It takes “developing environmental-friendly, economic and credible power generation equipment and technology for society” as its mission, and insists on the core value of “sharing, pursuit of truth, benefits, responsibility and innovation”, constantly improving its independent innovative ability and actively making contributions to the scientific and technological progress of the power equipment industry of China.


                  </p> <br />

                </div>
              </div>

            </div>}


          {id == "Condensate-Extraction-Pump" &&
            <div>
              {/* <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Electrical-Actuators')}>Electrical Actuators</a>&lt;-&gt; {names} &lt;-&gt;<a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Pumps-Boiler-Feed-Pumps,Condensate-Extraction-Pump,OCCW,CCW-Pump')}>Pumps-Boiler Feed Pumps, Condensate Extraction Pump, OCCW, CCW Pump </a>&lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/product-details/Gravimaetric-Coil-Feeder,EPC-Companies,Others')}>Gravimaetric Coil Feeder, EPC Companies, Others </a></p> */}
              <div className='space-between flex-wrap'>
                <h4>{cnames}</h4>
                <img src='https://www.etmex.net/images/logo03.jpg' />
              </div>
              <br />
              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.etmex.net/images/Changsha1.jpg' /><br />
                  <img src='https://www.etmex.net/images/Changsha2.jpg' />
                </div>
                <div class='text'>

                  <p>Hunan Changsha Pump Co., Ltd. (Changsha Pump Works) was founded in 1951, 60 years have been engaged in
                    the development and manufacture of pumps, are made ​​in China and China Machinery 500 500.</p>
                  <br /><p>Over the years, the company&#39;s products to obtain nearly 40 national research awards: 69APMA type
                    vertical mixed flow pumps, equipment for large-scale thermal power generating units of major technical
                    equipment Achievement Award, 200D43 mine drainage pump for energy-saving products Award, S150-50 single
                    stage double-suction centrifugal pump for quality awards, 28QZ-7.0 Axial submersible pumps for New
                    Product Award, vertical mixed flow water circulation pumps, 100FN-65 type urea melt pump for Science and
                    Technology Progress Award. In the East, deep engineering products, Baosteel Engineering, Shanghai raw
                    water, Shandong Electric Power and other major national projects, obtaining excellent mechanical and
                    electrical equipment award, best quality units, outstanding supplier award. Certain types of products
                    for the defense industry of China Shipbuilding Industry Award. Company logo was named CB brand products
                    consumers trust the brand in Hunan, CB Brand vertical mixed flow pumps in 2004, 2009, 2012 Hunan
                    Province famous brand products.
                  </p>
                  <br />
                  <br /><p>In recent years, the company is committed to product structure adjustment, new product development
                    and achieved fruitful results: LKXA.BC type vertical mixed flow pump, XJ type in a centrifugal pump, the
                    million-kilowatt nuclear power plant concrete volute pumps conventional island in the China
                    International won the gold medal on Fluid Machinery Exhibition; WNB series dredger pump, ABC Vertical
                    Multistage Condensate Pump Silver Award. Research and development of million-kilowatt nuclear power
                    plant water circulation pump duplex stainless steel impeller technology development projects for the
                    China Machinery Industry Science and Technology Award, marking the company to fully grasp the large
                    duplex stainless steel impeller manufacturing technology. Company and the Shanghai Nuclear Engineering
                    Research and Design Institute jointly developed China&#39;s first AP1000 residual heat removal pump
                    successfully developed for the realization of China&#39;s AP1000 residual heat removal pump localization to
                    contribute.
                  </p>
                  <br />
                  <br /><p>Beginning of the eighties, the company adhere to the road of development and innovation in the
                    introduction, met with the U.S. Fox (Flowserve), Ingersoll-Rand (Ingersoll-Rand), Levitra test
                    (Ellicott), Japan&#39;s Ebara (Ebara) Hitachi (Hitachi), Kubota (Kubote), Mapo UK, the Netherlands and other
                    internationally renowned Nijhuis Pumps Pump Company has established good relations and technological
                    cooperation.Currently the product has formed more than 50 series, more than 1,000 varieties, more than
                    2500 specifications. The main products are: large vertical mixed flow pumps, vertical condensation
                    pump, nuclear power pumps, marine dredging pump, concrete volute pumps, circulation pump, multistage
                    pump, vertical volute pumps, single-stage double suction centrifugal pump, submersible pumps, multistage
                    pumps, cantilever pumps, mud pumps, non-clogging pumps, sewage pumps and other products throughout the
                    energy, metallurgy, chemical industry, defense industry, building materials, textile, construction,
                    machinery , agricultural irrigation, environmental protection and other areas, selling the country and
                    exported to more than 50 countries and regions.
                  </p>
                  <br />
                  <br /><p>In 2006 the company implemented the relocation strategy, which lasted three years and the high-tech
                    zones in Changsha Lugu ningxiang through the open area to create a first-class machines were installed
                    base (headquarters) and casting base, with comprehensive technical, equipment, production capacity and
                    management system. Three-dimensional CAD, CAPP, ERP and CAE, PDM, CAM network techniques, from design,
                    manufacturing to management and service realized the information. Has the largest pump test center and
                    high-precision testing equipment, has excellent casting, machining, assembly, testing capabilities and
                    the cast, after welding, heat treatment and analytical testing methods, can achieve an annual output of
                    5,000 units of large pumps . The company has a sound quality assurance system, obtained ISO9001 quality
                    system certification, ISO14001 environmental management system and OHSAS18001 occupational health and
                    safety management system certification, ISO10012-1 measurement system certification, weapons and
                    equipment research and production, the system for qualification permits, NNSA awarded the design and
                    manufacturing qualification certificate civilian nuclear pressure equipment permits.
                  </p>
                  <br />
                  <br /><p>Currently, the company implemented the path of internationalization and high value-added strategic

                    roads, the adjustment of product structure, consolidate traditional markets, to nuclear power, military,
                    petrochemical, environmental protection and other areas of high-end product development. The company
                    follows the industry serve the country the concept of localization of major equipment to revitalize its
                    mission toward industry-leading, world-renowned goal inexhaustible passion, perseverance.
                  </p>
                  <br />
                  <br />
                  <br /><p>The company follows the principle of continuous product improvement and innovation,
                    adhere to the introduction of technology, co-developed manufacturing and the principle of
                    combining existing products 40 series, 590 varieties and 2008 specifications. The main
                    products are: 　　vertical mixed flow pump vertical condensation pump volute pump in a
                    vertical centrifugal submersible pump multi-stage axial flow pump is not blocked
                    cantilever pump mud pump dredge pump sewage pump 　　since the eighties , Ingersoll-Rand
                    Company from the United States introduced a vertical mixed flow pump technology, energy-
                    saving horizontal in a pump technology; from Japan Ebara the introduction of non-blocking
                    pump technology; from Japan, Hitachi introduced a large vertical condensation pump
                    technology; taken from the American company introduced Levitra dredger pump technology;
                    cooperation with the British玛珀company manufacturing the XY (Xiang Ying) Large-scale
                    pump; collaboration with Japanese Ebara manufacturing engineered pumps deep East .
                  </p>
                  <br />
                </div>
              </div>

            </div>}


          {id == 'others' &&
            <div>
              {/* <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/7')}> DC Motors </a> </p> */}
              {/* <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              <img src='https://www.etmex.net/images/AI-MI.jpg' />
            </div>
            <br /> */}
              <div style={{ display: 'grid' }}>
                <table style={{ width: '100%', borderStyle: "groove" }}>
                  <tr>
                    <td className='tdfRow'>OEM Name</td>
                    <td className='tdsRow'>Item</td>
                  </tr>
                  <tr>
                    <td className='tdf'>B-Tohin</td>
                    <td className='tds'>Spares of Air Blower installed at CPU regeneration and N-Pit Site</td>
                  </tr>
                  <tr>
                    <td className='tdf'>Tianjin Pumps</td>
                    <td className='tds'>Mechanical seal of HFO pressurizing pump</td>
                  </tr>
                  <tr>
                    <td className='tdf'>Fitok</td>
                    <td className='tds'>Valve manifold for Different Transmitter</td>
                  </tr>
                  <tr>
                    <td className='tdf'>Wuxi Huadong Electric Power Automation Instrument</td>
                    <td className='tds'>Intelligence data collects measure control Module (IDCB) for Boiler & Turbine</td>
                  </tr>
                  <tr>
                    <td className='tdf'>Tianjin Pumps</td>
                    <td className='tds'>mechanical seal of HFO pressurizing pump</td>
                  </tr><tr>
                    <td className='tdf'>Yuanpu Hydraulic & Lubrication equipment Co. Ltd.</td>
                    <td className='tds'>Spares of Lube Oil pump for PA Fan </td>
                  </tr><tr>
                    <td className='tdf'>SHIZHONG HELIE TRANSDUCERS Co. Ltd</td>
                    <td className='tds'>Supply of LVDT for GV </td>
                  </tr><tr>
                    <td className='tdf'>SPEM</td>
                    <td className='tds'>BFP spares</td>
                  </tr><tr>
                    <td className='tdf'>SPERI</td>
                    <td className='tds'>Coal Feeder</td>
                  </tr><tr>
                    <td className='tdf'>AI/MI</td>
                    <td className='tds'>Actuators</td>
                  </tr><tr>
                    <td className='tdf'>Changsa Pumps Works Co. Ltd. China</td>
                    <td className='tds'>CEP, CCW, ACW pumps</td>
                  </tr><tr>
                    <td className='tdf'>Jiangsu Huadian Machinery Co., Ltd</td>
                    <td className='tds'>Spares for CPU Regeneration System </td>
                  </tr><tr>
                    <td className='tdf'>Shanghai LEHE</td>
                    <td className='tds'>Mechanical seal of BFP/CEP</td>
                  </tr><tr>
                    <td className='tdf'>RAGA</td>
                    <td className='tds'>Actuators</td>
                  </tr><tr>
                    <td className='tdf'>Zhejiang Jiahuan Electronic Co. ltd.</td>
                    <td className='tds'>Spares for ESP control system</td>
                  </tr><tr>
                    <td className='tdf'>Shanghai Lianke Valve & Machinery Supplies Co. Ltd. (SUSV) </td>
                    <td className='tds'>Spares for Drain/Vent Valves</td>
                  </tr><tr>
                    <td className='tdf'>Changzhou Schneider</td>
                    <td className='tds'>Actuators</td>
                  </tr><tr>
                    <td className='tdf'>Shanghai Nikuni Pumps Co. Ltd.</td>
                    <td className='tds'>Pump with bearing box and coupling with intermediate shaft</td>
                  </tr><tr>
                    <td className='tdf'>Radktech</td>
                    <td className='tds'>Radk-tech servo valve for IV4</td>
                  </tr>
                  <tr><td className='tdf'>Ningbo Aqui</td>
                    <td className='tds'>Extraction #4 temperature thermocouple</td>
                  </tr><tr><td className='tdf'>Qingdao Power station valve co. Ltd.</td>
                    <td className='tds'>Complete actuator</td>
                  </tr><tr><td className='tdf'>Vickers Lubrication Oil Pump Co. Ltd.</td>
                    <td className='tds'>Lub Oil Pumps for FD/PA Fan & ID fan (fan side)</td>
                  </tr><tr><td className='tdf'>Dalian Instrument Transformer Co. Ltd.</td>
                    <td className='tds'>Spares for HT Switchgear</td>
                  </tr><tr><td className='tdf'>Shangbang</td>
                    <td className='tds'>Spares of Lube Oil pump for TDBFP </td>
                  </tr><tr><td className='tdf'>Qidong Jianghi</td>
                    <td className='tds'>Actuators</td>
                  </tr><tr><td className='tdf'>Zhejiang Jiahuan Electronic Co. ltd.</td>
                    <td className='tds'>Supply of spares ESP</td>
                  </tr><tr><td className='tdf'>Zhejiang Hanpu Electricity technology Co.</td>
                    <td className='tds'>Spares for AVR</td>
                  </tr><tr><td className='tdf'>Changzhou City Wujin Pump Co.</td>
                    <td className='tds'>Spares of HP seal oil backup pump</td>
                  </tr><tr><td className='tdf'>Angsu Haishi Pumps</td>
                    <td className='tds'>Supply of additional BTG electrical spares, spares of HP seal oil backup pump, spares of stator water pump & power contractors</td>
                  </tr><tr><td className='tdf'>Zhejiang Harnpu Power Technology Co.</td>
                    <td className='tds'>Supply of transduce</td>
                  </tr><tr><td className='tdf'>Shanghai Nikkiso Non-sseal Pump co. ltd.</td>
                    <td className='tds'>Supply of Nikkiso Non seal Pump without impeller Canned motor type</td>
                  </tr><tr><td className='tdf'>Chengdu ANFA Power Equipment Manufacturing Co. Ltd.</td>
                    <td className='tds'>Supply of Hydrogen Filling Station Valve</td>
                  </tr><tr><td className='tdf'>Okano </td>
                    <td className='tds'>Gate Valve, Globe Valve, Drain Valve, Check Valve, Control Valve, 3-Way Valve, Lining Valve, NAC Valve, Safety valve/relief valve</td>
                  </tr><tr><td className='tdf'>Changsha Swan Industrial Pump Co., Ltd.</td>
                    <td className='tds'>Circulating Water Pumps (CW pump)</td>
                  </tr><tr><td className='tdf'>Hunan Neptune Pump Co. Ltd.</td>
                    <td className='tds'>OCCW Pump</td>
                  </tr><tr><td className='tdf'>XCC</td>
                    <td className='tds'>Flame scanner, Oil Burner, Ignition Gun and Rod, MOIS</td>
                  </tr><tr><td className='tdf'>Shanghai Blower Works</td>
                    <td className='tds'>FD Fan, ID Fan, PA Fan</td>
                  </tr><tr><td className='tdf'>Shanghai Turbine Works</td>
                    <td className='tds'>Blades, Rotor and all other turbine spares</td>
                  </tr><tr><td className='tdf'>China Changsha</td>
                    <td className='tds'>CEP, CWP etc</td>
                  </tr><tr><td className='tdf'>Shijiazhuang Kingda Pump Ltd </td>
                    <td className='tds'>All Spares</td>
                  </tr><tr><td className='tdf'>Chongqing Gearbox Co.,</td>
                    <td className='tds'>Gearbox  Spares</td>
                  </tr><tr><td className='tdf'>Guomao Gearbox Co., Ltd</td>
                    <td className='tds'>Gearbox  Spares</td>
                  </tr><tr><td className='tdf'>CQ Gearbox spares</td>
                    <td className='tds'>Gearbox  Spares</td>
                  </tr><tr><td className='tdf'>KSB Shanghai Pump Ltd ( spares only ),</td>
                    <td className='tds'>OMEGA, CPK, CPKN, HGB, HGC,CHTC, CHTD, HPK(HPK-S, HPK-Y), HPH Series, MN, MF, KRTK Series, SEZ, SEZT, PHZ, PNZ Series, SQN series, YNKN Series</td>
                  </tr><tr><td className='tdf'>Shanghai Kaiquan Pump,</td>
                    <td className='tds'>Pump & pump spares</td>
                  </tr><tr><td className='tdf'>Shanghai East Pump,</td>
                    <td className='tds'>Pump & pump spares</td>
                  </tr><tr><td className='tdf'>Shanghai Liancheng Pump,</td>
                    <td className='tds'>Pump & pump spares</td>
                  </tr><tr><td className='tdf'>Shanghai Pumps Works,</td>
                    <td className='tds'>Pump & pump spares</td>
                  </tr><tr><td className='tdf'>SBW ( Shenyang Blower Works Group) Pump branch,</td>
                    <td className='tds'>Pump & pump spares</td>
                  </tr><tr><td className='tdf'>Shandong Shuanglun Pump,</td>
                    <td className='tds'>Pump & pump spares</td>
                  </tr><tr><td className='tdf'>Boshan Pump</td>
                    <td className='tds'>Pump & pump spares</td>
                  </tr><tr><td className='tdf'>KOSO</td>
                    <td className='tds'>All Spares</td>
                  </tr><tr><td className='tdf'>Wiscom</td>
                    <td className='tds'>All Spares</td>
                  </tr><tr><td className='tdf'>Beach Russ, USA</td>
                    <td className='tds'>Seal Oil Vacuum Pump</td>
                  </tr><tr><td className='tdf'>Radicon, Thailand</td>
                    <td className='tds'>Coupling</td>
                  </tr><tr><td className='tdf'>Wuhan Power Equipment Works, China</td>
                    <td className='tds'>Idler Wheel, Coupling, Pinion etc</td>
                  </tr><tr><td className='tdf'>Saimo Coal Feeders, China</td>
                    <td className='tds'>Coal Feeder and its products</td>
                  </tr><tr><td className='tdf'>Autork Actuators</td>
                    <td className='tds'>Electrical Actuators</td>
                  </tr>



                </table>

                {/* <div class='detail-container'>
              
              <div class='text'>
                <p className='bname'>OEM Name <b style={{fontWeight:600}} className='subcont'>Item</b></p>                               
                <ul style={{ marginLeft: '30px' }} className='inner-list'>
                <li className='inner-listone'><b className='cont'>B-Tohin:</b><b className='subcont'>spares of Air Blower installed at CPU regeneration and N-Pit Site</b></li>
                <li className='inner-listone'><b className='cont'>Tianjin Pumps:</b><b className='subcont'>mechanical seal of HFO pressurizing pump</b></li>
                <li className='inner-listone'><b className='cont'>Fitok:</b><b className='subcont'>valve manifold for Different Transmitter</b></li>
                <li className='inner-listone'><b className='cont'>Wuxi Huadong Electric Power Automation Instrument:</b><b className='subcont'>Intelligence data collects measure control Module (IDCB) for Boiler & Turbine</b></li>
                <li className='inner-listone'><b className='cont'>Yuanpu Hydraulic & Lubrication equipment Co. Ltd.:</b><b className='subcont'>spares of Lube Oil pump for PA Fan </b></li>
                <li className='inner-listone'><b className='cont'>SHIZHONG HELIE TRANSDUCERS Co. Ltd:</b><b className='subcont'>Supply of LVDT for GV </b></li>
                <li className='inner-listone'><b className='cont'>SPEM:</b><b className='subcont'>BFP spares</b></li>
                <li className='inner-listone'><b className='cont'>SPERI:</b><b className='subcont'>Coal Feeder</b></li>
                <li className='inner-listone'><b className='cont'>AI/MI:</b><b className='subcont'>Actuators</b></li>
                <li className='inner-listone'><b className='cont'>Changsa Pumps Works Co. Ltd. China:</b><b className='subcont'>CEP, CCW, ACW pumps</b></li>
                <li className='inner-listone'><b className='cont'>Jiangsu Huadian Machinery Co., Ltd:</b><b className='subcont'>spares for CPU Regeneration System </b></li>
                <li className='inner-listone'><b className='cont'>Shanghai LEHE:</b><b className='subcont'>Mechanical seal of BFP/CEP</b></li>
                <li className='inner-listone'><b className='cont'>RAGA:</b><b className='subcont'>Actuators</b></li>

           
                </ul>
              <br />
              <p className='bname'>Zhejiang Jiahuan Electronic Co. ltd.<b style={{fontWeight:600}} className='subcont'>of spares for ESP control system</b></p>                               
                <ul style={{ marginLeft: '30px' }} className='inner-list'>
                <li className='inner-listone'><b className='cont'>Shanghai Lianke Valve & Machinery Supplies Co. Ltd. (SUSV):</b><b className='subcont'>Spares for Drain/Vent Valves</b></li>
 <li className='inner-listone'><b className='cont'>Changzhou Schneider:</b><b className='subcont'>Actuators</b></li>
 <li className='inner-listone'><b className='cont'>Shanghai Nikuni Pumps Co. Ltd.:</b><b className='subcont'>pump with bearing box and coupling with intermediate shaft</b></li>
 <li className='inner-listone'><b className='cont'>Radktech:</b><b className='subcont'>Radk-tech servo valve for IV4</b></li>
 <li className='inner-listone'><b className='cont'>Ningbo Aqui:</b><b className='subcont'>Extraction #4 temperature thermocouple</b></li>
 <li className='inner-listone'><b className='cont'>Qingdao Power station valve co. Ltd.:</b><b className='subcont'>complete actuator</b></li>
 <li className='inner-listone'><b className='cont'>Vickers Lubrication Oil Pump Co. Ltd.:</b><b className='subcont'>Lub Oil Pumps for FD/PA Fan & ID fan (fan side)</b></li>
 <li className='inner-listone'><b className='cont'>Dalian Instrument Transformer Co. Ltd.:</b><b className='subcont'>Spares for HT Switchgear</b></li>
 <li className='inner-listone'><b className='cont'>Shangbang:</b><b className='subcont'>spares of Lube Oil pump for TDBFP </b></li>
 <li className='inner-listone'><b className='cont'>Qidong Jianghi:</b><b className='subcont'>actuators</b></li>
 <li className='inner-listone'><b className='cont'>Zhejiang Jiahuan Electronic Co. ltd.:</b><b className='subcont'>supply of spares ESP</b></li>
 <li className='inner-listone'><b className='cont'>Zhejiang Hanpu Electricity technology Co.:</b><b className='subcont'>Spares for AVR</b></li>
 <li className='inner-listone'><b className='cont'>Changzhou City Wujin Pump Co.:</b><b className='subcont'>spares of HP seal oil backup pump</b></li>
 <li className='inner-listone'><b className='cont'>Angsu Haishi Pumps:</b><b className='subcont'>supply of additional BTG electrical spares, spares of HP seal oil backup pump, spares of stator water pump & power contractors</b></li>
 <li className='inner-listone'><b className='cont'>Zhejiang Harnpu Power Technology Co.:</b><b className='subcont'>supply of transduce</b></li>
 <li className='inner-listone'><b className='cont'>Shanghai Nikkiso Non-sseal Pump co. ltd.:</b><b className='subcont'>supply of Nikkiso Non seal Pump without impeller Canned motor type</b></li>
 <li className='inner-listone'><b className='cont'>Chengdu ANFA Power Equipment Manufacturing Co. Ltd.:</b><b className='subcont'>Supply of Hydrogen Filling Station Valve</b></li>
 <li className='inner-listone'><b className='cont'>Okano :</b><b className='subcont'>Gate Valve, Globe Valve, Drain Valve, Check Valve, Control Valve, 3-Way Valve, Lining Valve, NAC Valve, Safety valve/relief valve</b></li>
 <li className='inner-listone'><b className='cont'>Changsha Swan Industrial Pump Co., Ltd.:</b><b className='subcont'>Circulating Water Pumps (CW pump)</b></li>
 <li className='inner-listone'><b className='cont'>Hunan Neptune Pump Co. Ltd.:</b><b className='subcont'>OCCW Pump</b></li>
 <li className='inner-listone'><b className='cont'>XCC:</b><b className='subcont'>Flame scanner, Oil Burner, Ignition Gun and Rod, MOIS</b></li>
 <li className='inner-listone'><b className='cont'>Shanghai Blower Works:</b><b className='subcont'>FD Fan, ID Fan, PA Fan</b></li>
 <li className='inner-listone'><b className='cont'>Shanghai Turbine Works:</b><b className='subcont'>Blades, Rotor and all other turbine spares</b></li>
 <li className='inner-listone'><b className='cont'>China Changsha:</b><b className='subcont'>CEP, CWP etc</b></li>
 <li className='inner-listone'><b className='cont'>Shijiazhuang Kingda Pump Ltd :</b><b className='subcont'>All Spares</b></li>
 <li className='inner-listone'><b className='cont'>Chongqing Gearbox Co.,:</b><b className='subcont'>Gearbox  Spares</b></li>
 <li className='inner-listone'><b className='cont'>Guomao Gearbox Co., Ltd:</b><b className='subcont'>Gearbox  Spares</b></li>
 <li className='inner-listone'><b className='cont'>CQ Gearbox spares:</b><b className='subcont'>Gearbox  Spares</b></li>
 <li className='inner-listone'><b className='cont'>KSB Shanghai Pump Ltd ( spares only ),:</b><b className='subcont'>OMEGA, CPK, CPKN, HGB, HGC,CHTC, CHTD, HPK(HPK-S, HPK-Y), HPH Series, MN, MF, KRTK Series, SEZ, SEZT, PHZ, PNZ Series, SQN series, YNKN Series</b></li>
 <li className='inner-listone'><b className='cont'>Shanghai Kaiquan Pump,:</b><b className='subcont'>Pump & pump spares</b></li>
 <li className='inner-listone'><b className='cont'>Shanghai East Pump,:</b><b className='subcont'>Pump & pump spares</b></li>
 <li className='inner-listone'><b className='cont'>Shanghai Liancheng Pump,:</b><b className='subcont'>Pump & pump spares</b></li>
 <li className='inner-listone'><b className='cont'>Shanghai Pumps Works,:</b><b className='subcont'>Pump & pump spares</b></li>
 <li className='inner-listone'><b className='cont'>SBW ( Shenyang Blower Works Group) Pump branch,:</b><b className='subcont'>Pump & pump spares</b></li>
 <li className='inner-listone'><b className='cont'>Shandong Shuanglun Pump,:</b><b className='subcont'>Pump & pump spares</b></li>
 <li className='inner-listone'><b className='cont'>Boshan Pump:</b><b className='subcont'>Pump & pump spares</b></li>
 <li className='inner-listone'><b className='cont'>KOSO:</b><b className='subcont'>All Spares</b></li>
 <li className='inner-listone'><b className='cont'>Wiscom:</b><b className='subcont'>All Spares</b></li>
 <li className='inner-listone'><b className='cont'>Beach Russ, USA:</b><b className='subcont'>Seal Oil Vacuum Pump</b></li>
 <li className='inner-listone'><b className='cont'>Radicon, Thailand:</b><b className='subcont'>Coupling</b></li>
 <li className='inner-listone'><b className='cont'>Wuhan Power Equipment Works, China:</b><b className='subcont'>Idler Wheel, Coupling, Pinion etc</b></li>
 <li className='inner-listone'><b className='cont'>Saimo Coal Feeders, China:</b><b className='subcont'>Coal Feeder and its products</b></li>
 <li className='inner-listone'><b className='cont'>Autork Actuators:</b><b className='subcont'>Electrical Actuators</b></li>
 
                  
                </ul>
              </div>
            </div> <br /> */}


              </div>


            </div>}
        </div>
      </section>
    </>
  )
}
