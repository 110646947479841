// const appConfig = {
//     “OAUTH_CLIENT_ID”: “mySTAGINGappoauthclientid”,
//     “OAUTH_REDIRECT_URI”: “https://staging.myapp.com/",
//     “ANALYTICS_URL”: “https://staging.analytics.com"
//    };

//    module.exports = appConfig;

const appConfig = {
    URL:"https://staging.liviapp.com",
    APIURL:"https://staging.liviapp.com/api",
}
module.exports=appConfig