import { BrowserRouter, Route, Routes } from "react-router-dom";
import Details from "../screens/product-details";
import Home from "../screens/home";

function Router(){
    return(
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home  />} />
          <Route path="/product-details/:id" element={<Details  />} />
        </Routes>
      </BrowserRouter>
    )
}

export default Router