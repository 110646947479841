import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import menuIcon from '../../assets/images/menu.png';
import logo from '../../assets/images/logo.png';
import './header.css';
import '../../App.css'
import '../../fonts/Bernhard Fashion Std Regular.otf'

export default function Header() {
    const navigate = useNavigate();
    const [isOpenMenu, setOpenMenu] = useState(false)

    const toggalMenu = () => {
        let isOpen = !isOpenMenu
        setOpenMenu(isOpen)
    }

    return (
        <div className="page-header">
            <div className='container space-between'>
                <a onClick={() => navigate('/')} className="logo-cover" title='Home'>
                    <img src={logo} alt="Logo" />
                    <h2>Etmex Overseas</h2>
                </a>
                <div className="right-portion justify-end">
                    <ul className="links-cover">
                        <li className='single-link' onClick={() => navigate('/')}><a href="#Home">Home</a></li>
                        <li className='single-link' onClick={() => navigate('/')}><a href="#Aboutus">About Us</a></li>
                        <li className='single-link'>
                            <div className='dropdown-cover'>
                                <div className='drop-btn'>
                                    Our Products
                                </div>
                                <ul className='drop-content'>
                                    <li onClick={() => navigate(`/product-details/Electrical-Actuators`)} >Electrical Actuators</li>
                                    <li onClick={() => navigate('/product-details/Mechanical-Seals')}>Mechanical Seals</li>
                                    <li onClick={() => navigate('/product-details/Pumps-Boiler-Feed-Pumps')}>Boiler Feed Pump & Booster Pump</li>
                                    <li onClick={() => navigate('/product-details/Condensate-Extraction-Pump')}>Condensate Extraction Pump</li>
                                    <li onClick={() => navigate('/product-details/Gravimaetric-Coil-Feeder')} >Gravimetric Coal Feeders</li>
                                    <li onClick={() => navigate('/product-details/others')} >Others...</li>
                                </ul>
                            </div>
                        </li>
                        <li className='single-link' onClick={() => navigate('/')}><a href="#Customers">Customers</a></li>
                        <li className='single-link' onClick={() => navigate('/')}><a href="#OurServices">Our Services</a></li>
                        <li className='single-link' onClick={() => navigate('/')}><a href="#ContactUs">Contact Us</a></li>
                    </ul>
                    <div className="for-mobile">
                        <div className="hamburger-cover" onClick={toggalMenu}>
                            <img src={menuIcon} alt="Menu" />
                        </div>
                        <div className={isOpenMenu ? "sidebar-cover active" : "sidebar-cover"}>
                            <div className="sidebar">
                                <ul>
                                    <li onClick={() => navigate('/')}><a onClick={toggalMenu} href="#Home">Home</a></li>
                                    <li onClick={() => navigate('/')}><a onClick={toggalMenu} href="#Aboutus">About Us</a></li>

                                    <li>
                                        <div className='dropdown-cover'>
                                            <div className='drop-btn'>
                                                Our Products
                                            </div>
                                            <ul className='drop-content-mob'>
                                                <li onClick={() => navigate(`/product-details/Electrical-Actuators`)} ><a onClick={toggalMenu}>Electrical Actuators</a></li>
                                                <li onClick={() => navigate('/product-details/Mechanical-Seals')}><a onClick={toggalMenu}>Mechanical Seals</a></li>
                                                <li onClick={() => navigate('/product-details/Pumps-Boiler-Feed-Pumps')}><a onClick={toggalMenu}>Boiler Feed Pump & Booster Pump</a></li>
                                                <li onClick={() => navigate('/product-details/Condensate-Extraction-Pump')}><a onClick={toggalMenu}>Condensate Extraction Pump</a></li>
                                                <li onClick={() => navigate('/product-details/Gravimaetric-Coil-Feeder')} ><a onClick={toggalMenu}>Gravimetric Coal Feeders</a></li>
                                                <li onClick={() => navigate('/product-details/others')} ><a onClick={toggalMenu}>Others...</a></li>
                                                {/* <li>Tianjin Baliertong Machinery Co. Ltd</li>
                                                <li>Changzhou Schneider Actuators</li>
                                                <li>RAGA Make Actuators</li>
                                                <li>Shanghai Lehe Mechanical Seals</li>
                                                <li>SPEM Co. Ltd</li>
                                                <li>Hunan Changsha Pump Co. Ltd</li>
                                                <li>Ebara Baoshan Pump Co. Ltd</li>
                                                <li>SPERI Make Coal Feeders</li> */}
                                            </ul>
                                        </div>
                                    </li>
                                    <li onClick={() => navigate('/')}><a onClick={toggalMenu} href="#Customers">Customers</a></li>
                                    <li onClick={() => navigate('/')}><a onClick={toggalMenu} href="#OurServices">Our Services</a></li>
                                    <li onClick={() => navigate('/')}><a onClick={toggalMenu} href="#ContactUs">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}