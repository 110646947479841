import React, { useState } from 'react';
import './home.css';
import Header from '../header/header';
// import Modal from "react-bootstrap/Modal";
// import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import facebookIcon from './../../assets/images/facebook-icon.svg';
import instaIcon from './../../assets/images/insta-icon.svg';
import twitterIcon from './../../assets/images/twitter.svg';
import youtubeIcon from './../../assets/images/youtube-icon.svg';
import bannerImg from './../../assets/images/banner.jpeg';
import chooseUs from './../../assets/images/chooseus.jpeg';
import principal from './../../assets/images/ourprincipal.jpeg';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import appConfig from '../../config/appConfig'
import { useEffect } from 'react';
const AutoplaySlider = withAutoplay(AwesomeSlider);

const logo = ['https://www.etmex.net/images/Adani.jpg',
    'https://www.etmex.net/images/aparvaa.jpg',
    'https://www.etmex.net/images/BALCO.png',
    'https://www.etmex.net/images/CESC.png',
    'https://www.etmex.net/images/Coastal.jpg',
    'https://www.etmex.net/images/Damodar.png',
    'https://www.etmex.net/images/Essar.jpg',
    'https://www.etmex.net/images/GMR.png',
    'https://www.etmex.net/images/HPGCL.png',
    'https://www.etmex.net/images/IL&FS.png',
    'https://www.etmex.net/images/jindal-power-logo.png',
    'https://www.etmex.net/images/JSPL.png',
    'https://www.etmex.net/images/KSK.jpg',
    'https://www.etmex.net/images/Lanco.png',
    'https://www.etmex.net/images/Maruti.jpg',
    'https://www.etmex.net/images/Nabha.png',
    'https://www.etmex.net/images/reliance-energy-Logo.png',
    'https://www.etmex.net/images/RKM.png',
    'https://www.etmex.net/images/RP-Sanjiv_Goenka_Group_logo.png',
    'https://www.etmex.net/images/RVUNL.jpg',
    'https://www.etmex.net/images/Sembcorp.jpg',
    'https://www.etmex.net/images/TANGEDCO-Logo.jpg',
    'https://www.etmex.net/images/Utkal.jpg',
    'https://www.etmex.net/images/Vedanta_logo_tagline_white_bg.jpg',
    'https://www.etmex.net/images/Vishnu.jpg',
    'https://www.etmex.net/images/wbpdcl-logo-transparent.png']




export default function Home() {
    const navigate = useNavigate();
    const [modalOpen, setModelOpen] = useState("false")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const renderLog = () => {
        logo.map((res) => {
            return <>
                <div className='list-img'>
                    <img src={logo[3]} />
                </div>
            </>
        })
    }

    return (
        <>
            <Header />

            <main>
                <section className="banner-section" id="Home">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-6 col-md-12'>
                                <h1>Looking for Genuine Spares for your Capital Equipments?</h1>
                                <h1>Look no further. We are here to help!</h1>
                                <h3>We offer 100% genuine Spares and timely Service to power plants/process Industries globally.</h3>
                                {/* <button className='btn-primary'>Get Free consultation</button> */}
                            </div>
                            <div className='col-6 col-md-12'>
                                <div className='banner-img-cover'>
                                    <img src={bannerImg} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-3 col-xl-6 col-sm-12'>
                                        <div className='feature-icon'>
                                            <i className='fa fa-id-card-o' />
                                            <h4>A team of industry experts to understand your requirements</h4>
                                        </div>
                                    </div>
                                    <div className='col-3 col-xl-6 col-sm-12'>
                                        <div className='feature-icon'>
                                            <i className='fa fa-th-large' />
                                            <h4>100% genuine spares only from OEM(no middle-men involved)</h4>
                                        </div>
                                    </div>
                                    <div className='col-3 col-xl-6 col-sm-12'>
                                        <div className='feature-icon'>
                                            <i className='fa fa-handshake-o' />
                                            <h4>OEM trained service engineers to solve technical issues</h4>
                                        </div>
                                    </div>
                                    <div className='col-3 col-xl-6 col-sm-12'>
                                        <div className='feature-icon'>
                                            <i className='fa fa-comments-o' />
                                            <h4>Prompt response and timely deliveries</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section" id="Aboutus">
                    <div className='container'>
                        <h3>About Us</h3>
                        <div>
                            <p>We are a well-established name in the field of marketing of Industrial & Electrical equipment. We are also the exclusive authorized dealers of OEM’s. We are based in the capital city of India and are catering to clients in the majority of places from Northern, Central and Eastern India. The promoters of the company have vast experience in the same field and also of the workflow and management of the same.</p>
                            <p>We specialize in promoting sales of overseas reputed brand products in India and providing after-sales support / services. Our product range includes: Electrical Actuators, Mechanical Seals, Boiler Feed Pumps, Gravimetric Coal Feeders, High Pressure Valves, Other C&I equipment and their spare parts.</p>
                            <p>We emphasize doing honest business with both OEM & end customer. Our target is to provide after sales service and to bridge the gap between end user and OEM which is not just for business purpose but also to gain confidence in our customers. Our basic objective of developing business both in India and aboard is to provide complete equipment and its spares ensuring interchange-ability with the existing installation.</p>
                            <p>Our company has qualified and efficient personal for inspection, and, are associated with Government Approved laboratories to ensure that whatever materials are supplied by us are as per required specification. We have vast experience in various fields and would take up any challenging retrofit job guaranteed with one to one replacement at site.</p>
                            <p>We have gained sufficient experience in this field and achieved substantial business from reputed concerns and Government Sectors on repeat order basis in India as well as from abroad. This marks the beginning of an endless journey started to reach, satisfy and keep long lasting relationship with our customers. Your satisfaction is the award for our good quality and service.</p>
                        </div>
                    </div>

                </section>
                <section className="welcome-section">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-6 col-lg-12'>
                                <h4>Welcome to ETMEX Overseas</h4>
                                <h3>Risk more than others think is safe. Dream more than others think is practical.</h3>
                                <button className='btn-primary'><a href="#ContactUs">Let's Discuss Your Case</a></button>
                            </div>
                            <div className='col-6 col-lg-12'>
                                <ul>
                                    <li><a style={{ color: 'gray' }} href="#WhyChooseUs">Why choose us </a></li>
                                    <li><a onClick={() => navigate('/product-details/others')} >Our Principles</a></li>
                                    <li><a style={{ color: 'gray' }} href="#Customers">Our Customers </a></li>
                                </ul>
                                <div className='row mb-20'>
                                    <div className='col-6'>
                                        <img src={principal} className="w-100" />
                                    </div>
                                    <div className='col-6' >
                                        <img src={chooseUs} className="w-100" />
                                    </div>
                                </div>
                                <p>ETMEX Overseas is the one stop solution who understand customer problems deeply from all dimensions Like Language Problem, understanding of which Spare part is needed,
                                    requirement of Service Support / Installation Support, Friendly Payment terms, Lightenign fast Delivery time to meet customer urgency and with all the FAITH & TRUST.</p>
                            </div>
                        </div>
                        </div>
                </section>
                <section className="card-section" id="WhyChooseUs">
                    <h3>Why choose our company</h3>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-card'>
                                    <h4>Trustworthy</h4>
                                    <h5>Our verified network of OEM's are reliable, can vouch for us and vice versa.</h5>
                                </div>
                            </div>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-card'>
                                    <h4>Long-term relationship</h4>
                                    <h5>We create a long-term relationship with clients, so that the delivery of their products becomes a seamless experience that promotes loyalty.</h5>
                                </div>
                            </div>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-card'>
                                    <h4>Integrity</h4>
                                    <h5>We hold honesty and transparency in the highest regard.</h5>
                                </div>
                            </div>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-card'>
                                    <h4>OEM VERIFICATION & QC</h4>
                                    <h5>We verify capabilities of every OEM & reasonably ensure the quality of the finished products.</h5>
                                </div>
                            </div>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-card'>
                                    <h4>Favourable Pricing</h4>
                                    <h5>OEM, we ensure better pricing.</h5>
                                </div>
                            </div>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-card'>
                                    <h4>Better Communication</h4>
                                    <h5>We can understand your needs & communicate them to OEM with ease.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="building-section" id="Customers">
                    <div className="building-content container">
                        <h3>Our Customers</h3>
                        <div className='gallery-img'>
                            {
                                logo.map((res) => {
                                    return <>
                                        <div className='single-img'>
                                            <img src={res} />
                                        </div>
                                    </>
                                })
                            }
                        </div>
                    </div>
                </section>

                {/* <section className='counter-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-3 col-xl-6 col-sm-12'>
                                <h2>Experienced specialists</h2>
                                <p>Quisque in suscipit nisi, at auctor orci. Sed posuere ullamcorper molestie.
                                    Suspendisse euismod felis nunc, vitae aliquet lectus ornare.</p>
                            </div>
                            <div className='col-3 col-xl-6 col-sm-12'>
                                <h2>Stable & predictable results</h2>
                                <p>Quisque in suscipit nisi, at auctor orci. Sed posuere ullamcorper molestie.
                                    Suspendisse euismod felis nunc, vitae aliquet lectus ornare.</p>
                            </div>
                            <div className='col-3 col-xl-6 col-sm-12'>
                                <h2>100% Satisfaction guarantee</h2>
                                <p>Quisque in suscipit nisi, at auctor orci. Sed posuere ullamcorper molestie.
                                    Suspendisse euismod felis nunc, vitae aliquet lectus ornare.</p>
                            </div>
                            <div className='col-3 col-xl-6 col-sm-12'>
                                <h2>20+ Years in consulting business</h2>
                                <p>Quisque in suscipit nisi, at auctor orci. Sed posuere ullamcorper molestie.
                                    Suspendisse euismod felis nunc, vitae aliquet lectus ornare.</p>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="our-services-section" id='OurServices'>
                    <div className='container'>
                        <h3>Our Services</h3>
                        <div className='row'>
                            <div className='col-8 col-lg-12'>
                                {/* <h4>Servicing of equipments:-</h4> */}
                                <div className='single-service'>
                                    <h5>SPEM Repair India Pvt. Ltd</h5>
                                    <p>SPEM Authorised Service Centre.</p>
                                    <p>We at ETMEX are working towards the future, we realise that pure imports is not a long term game, the bigger plan is to bring the best things to India. SPEM repair centre is our first feather in the crown. We have entered into a Joint-venture with our principals SPEM & mutually agreed to open up a facility purchased at HSIIDC, Haryana for repair & maintenance of Boiler feed pumps. The goal of SRC is to help customers improve the operational performance of their equipment while lowering their total cost of ownership. This canter would cater to servicing needs from pan-India basis. SRC if fully tooled & staffed to handle all facets of equipment maintenance & repair, including:-</p>
                                    <ul className='myservice'>
                                        <li className='myserList'>Preventive & scheduled maintenance</li>
                                        <li className='myserList'>Equipment repair, analysis & testing</li>
                                        <li className='myserList'>Machining & repair welding</li>
                                        <li className='myserList'>Assess, upgrades & retrofits</li>
                                        <li className='myserList'>Vibration & noise monitoring / analysis</li>
                                        <li className='myserList'>Lubrication analysis & service</li>
                                        <li className='myserList'>Parts & component stocking supply</li>
                                    </ul>
                                    <br /><p>
                                        Since inception, we have successfully repaired about 30 SPEM cartridges and 05 other brand  cartridges are in workshop for repair.
                                    </p>
                                    <br /><p>
                                        24 x 7 technical presence of SPEM engineers at the centre.
                                    </p>
                                    <br />
                                </div>
                                <div className='single-service'>
                                    <h5>ACTUATOR SERVICE & REPAIR FACILITY</h5>
                                    <p>We have in-house OEM trained Service Engineers who can attend to any fault at your site.</p>
                                </div>
                                <div className='single-service'>
                                    <h5>Repair of Mechanical Seals</h5>
                                    <p>With co-operation with our OEM we are successfully repairing Shanghai LEHE seals for all our customers in India. We undertake Guarantee Warrantee for our repaired seals similar to that of new seals.</p>
                                </div>
                                <div className='single-service'>
                                    <h5>SPERI GRAVIMETRIC COAL FEEDER CARD REPAIR FACILITY</h5>
                                    <p>We have entered into a co-operation with the OEM for repair of electronic cards installed in Coal feeders by the OEM itself from China. Cards can be sent to OEM works for repairs & returned after completion of repair job.</p>
                                </div>

                                <div className='single-service'>
                                    <h5>Servicing of Turbine (Capital Overhauling)</h5>
                                    <p>We have recently started servicing of Turbine (150MW, 300 MW, 600 MW). With our newly founded team, we have successfully done major overhauling of turbines of capacity 300MW & many more enquiries are in pipeline.</p>
                                </div>

                            </div>
                            <div className='col-4 col-lg-12'>
                                <div className='img-cover'>
                                    <img src='https://www.etmex.net/images/Service021.jpg' /> <br />
                                    <img src='https://www.etmex.net/images/Service011.jpg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='any-question-section' id="ContactUs">
                    <div className='container'>
                        <h1>Any questions? Request a call-back to get a free analysis of your business!</h1>
                        <form>
                            <div className='row'>
                                <div className='col-6 col-sm-12'>
                                    Etmex Overseas <br />
                                    Delhi, India<br />
                                    www.etmex.net
                                </div>
                                <div className='col-6 col-sm-12'>
                                    Phone: 011 27122146
                                    Fax: 011 27122146
                                    Mobile: +91-8860568949
                                    <p> <a href="mailto:delhi@etmex.net">delhi@etmex.net</a></p>

                                </div>
                                {/* <div className='col-12'>
                                    <button className='btn-primary'>Request a Call Back</button>
                                </div> */}
                            </div>
                        </form>

                    </div>
                </section>
                {/* <section className="why-choose-section">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-6 col-lg-12'>
                                <h4>Why choose our company</h4>
                                <h3>Here's how things are going to change for your business</h3>
                                <ul>
                                    <li>150-180% more lorem volutpat donec glavrida ipsum dolor</li>
                                    <li>Reative volutpat donec glavrida ipsum</li>
                                    <li>Class aptent taciti sociosqu ad litora</li>
                                    <li>100-200% of dolor nulla gravida et ipsum nulla</li>
                                    <li>Amet lorem felis - dolor nulla gravida et nulla gravida</li>
                                </ul>
                            </div>
                            <div className='col-6 col-lg-12'>
                                <div className='video-cover'>
                                    <iframe frameborder="0" marginheight="0" marginwidth="0"
                                        type="text/html"
                                        src="https://www.youtube.com/embed/DBXH9jJRaDk?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="review-section">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-review'>
                                    <i className='fa fa-star ' />
                                    <p>Consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis,
                                        pulvinar dapibus leo. Unbelievable!</p>
                                    <h4>Maria Green</h4>
                                    <h6>Seven Dev - marketing director</h6>
                                </div>
                            </div>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-review'>
                                    <i className='fa fa-star ' />
                                    <p>Consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis,
                                        pulvinar dapibus leo. Unbelievable!</p>
                                    <h4>Maria Green</h4>
                                    <h6>Seven Dev - marketing director</h6>
                                </div>
                            </div>
                            <div className='col-4 col-lg-6 col-sm-12'>
                                <div className='single-review'>
                                    <i className='fa fa-star ' />
                                    <p>Consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis,
                                        pulvinar dapibus leo. Unbelievable!</p>
                                    <h4>Maria Green</h4>
                                    <h6>Seven Dev - marketing director</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

            </main>
            <footer>
                <div className='container'>
                    <p>© Etmex Pvt Ltd - 2022. All rights reserved.</p>
                    <div class="social-icons mt-20">
                        <a href="#" title='Facebook'><img src={facebookIcon} /></a>
                        <a href="#" title='Twitter'><img src={twitterIcon} /></a>
                        <a href="#" title='Instagram'><img src={instaIcon} /></a>
                        <a href="#" title='Youtube'><img src={youtubeIcon} /></a>
                    </div>
                </div>
            </footer>
        </>
    )
}
